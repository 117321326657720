import './App.css';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import { ReactSpinner } from 'react-spinning-wheel';
import 'react-spinning-wheel/dist/style.css';
import Switch from "react-switch";
import jQuery from 'jquery';


function App() {

  // Dev stuff
  // USE WHEN DEPLOYED:
  const PATCHSTACK_URL = `https://patchstack.com/database/api/v2/batch`
  // USE LOCALLY:
  // const PATCHSTACK_URL = `http://localhost:8010/proxy/database/api/v2/batch`
  // Use when testing to avoid going over our call limit
  const [testMode, setTestMode] = useState(false);

  // Keys
  const CONSUMER_KEY = process.env.REACT_APP_CONSUMER_KEY
  const CONSUMER_SECRET = process.env.REACT_APP_CONSUMER_SECRET
  // console.log(CONSUMER_SECRET)
  const PS_KEY = process.env.REACT_APP_PS_KEY

  // Urls
  const SITE_PLUGINS_URL = `https://mainwp.pixeldesigns.ca/wp-json/mainwp/v1/site/site-installed-plugins?site_id=`
  const CREDENTIALS = `consumer_key=${CONSUMER_KEY}&consumer_secret=${CONSUMER_SECRET}`
  const MAINWP_URL = `https://mainwp.pixeldesigns.ca/wp-json/mainwp/v1/sites/all-sites?consumer_key=${CONSUMER_KEY}&consumer_secret=${CONSUMER_SECRET}`



  const [status, setStatus] = useState("")
  const [errors, setErrors] = useState([])
  const [vulnerabilities, setVulnerabilities] = useState([])
  const [earliestVersions, setEarliestVersions] = useState({})
  const [display, setDisplay] = useState("all")

  let plugins = []
  const semver = require('semver')

  function arrayWithoutDuplicates(arr) {
    Array.from(new Set(arr))
  }

  function needsUpdate(vulnPlugin, earliestVersion) {

    let doesNeedUpdate = true;
    // Compares affected in, fixed in, and earliest version we havei nstalled
    if (vulnPlugin.affected_in.includes("<=")) {
      let earliestAffected = vulnPlugin.affected_in.replace("<= ", "")
      if (!semver.lte(earliestVersion, earliestAffected)) {
        doesNeedUpdate = false
      }
    } else if (vulnPlugin.fixed_in) {
      try {
        if (!semver.lte(earliestVersion, vulnPlugin.fixed_in)) {
          doesNeedUpdate = false;
        }
      } catch (e) {
        alert(e)
      }
    }
    return doesNeedUpdate
  }

  const Vulnerability = ({ vulnPlugin }) => {

    let doesNeedUpdate = needsUpdate(vulnPlugin, earliestVersions[vulnPlugin.name])

    return (
      <div>
        <h3>{vulnPlugin.product_name}</h3>
        <p>Versions affected: {vulnPlugin.affected_in}</p>
        <p>Type: {vulnPlugin.vuln_type}</p>
        <p>Fixed in: {vulnPlugin.fixed_in ? `${vulnPlugin.fixed_in}` : ""}</p>
        <p>Earliest: {earliestVersions[vulnPlugin.name]}</p>

        {doesNeedUpdate ?
          <b>Update now!</b>
          :
          <p>No action required</p>
        }
      </div>
    )

  }

  async function patchstackFetch(batch, earliestVersionsTemp) {
    // fetch(PATCHSTACK_URL, {
    //   "Access-Control-Allow-Origin": "*",
    //   method: 'POST',
    //   headers: {
    //     "PSKey": PS_KEY
    //   },
    //   body: JSON.stringify(batch)
    // })
    // ^^convert this into php^^

    const newVulns = []
    jQuery.ajax({
      type: "POST",
      data: { 'batch': JSON.stringify(batch) },
      url: "https://vulnchecker.pixeldesigns.ca/patchstackfetch.php",
      error: function (e) {
        setErrors([e.message])
        console.log(e)
      },
      success: function (resJson) {
        let results = JSON.parse(resJson);
        for (const k of Object.keys(results.vulnerabilities)) {
          let vulnList = results.vulnerabilities[k]
          if (vulnList.length > 0) {
            for (const vulnPlugin of vulnList) {
              newVulns.push({
                ...vulnPlugin,
                // it only includes it as the key, but we need a lsit
                'name': k,
                'needsUpdate': needsUpdate(vulnPlugin, earliestVersionsTemp[k])
              })

            }
          }
        }
        setVulnerabilities(arr => [...arr, ...Array.from(new Set(newVulns))])

      }
    })

  }
  async function checkVulns() {


    setStatus("Getting URLs")

    let urlList = await fetch(MAINWP_URL).then((res) =>
      res.json()
    ).catch((e) => {
      setErrors([e.message])
    })
    setStatus("Getting plugins list (this one takes awhile)")

    let j = 0
    const earliestVersionsTemp = {}

    for (const url of Object.values(urlList)) {
      j += 1
      // !! If testing things, use this to prevent going over our limit of Patchstack calls.
      if (testMode && j > 15) {
        break;
      }

      const pluginsURL = `${SITE_PLUGINS_URL}${url.id}&${CREDENTIALS}`
      let pluginsOnSite = await fetch(pluginsURL).then((res) =>
        res.json()
      )
      // 🍝
      try {
        pluginsOnSite.forEach((pluginData) => {
          let plugin = {
            name: pluginData.slug.split("/")[0],
            version: pluginData.version,
          }
          plugins[plugin.name] = {
            name: plugin.name,
            versions: !!plugins[plugin.name] ? Array.from(new Set([...plugins[plugin.name].versions, plugin.version])) : [plugin.version],
            type: "plugin",
            exists: false
          }
          if (earliestVersionsTemp[plugin.name] == null || (earliestVersionsTemp[plugin.name] > plugin.version)) {
            earliestVersionsTemp[plugin.name] = plugin.version
          }
        })
      } catch {
        setErrors(["Unable to get plugins."])
      }
    }
    setEarliestVersions(earliestVersionsTemp)


    // setUrlsToPlugins(urlsToPluginsTemp)
    // Convert into a json-friendly list
    let pluginsList = []
    for (const pluginKey of Object.keys(plugins)) {
      let plugin = plugins[pluginKey]
      for (const version of plugin.versions) {
        let a = ({
          ...plugin,
          version: version
        })
        delete a.versions
        pluginsList.push(a)
      }
    }

    // Chunkify the plugins
    const chunkSize = 50;
    for (let i = 0; i < pluginsList.length; i += chunkSize) {

      const batch = pluginsList.slice(i, i + chunkSize);
      setStatus("Checking plugins for vulnerabilities...")
      await patchstackFetch(batch, earliestVersionsTemp);
    }
    // Remove duplicates
    setStatus("Done")


  }

  return (
    <div className="App">
      <header className="App-header">
        <h3>Pixel's Synergistic Automagical Plug-Buster</h3>
        <p>This app checks all plugins installed on all sites across MainWP, then checks Patchstack for vulnerabilities. It then compares the earliest version we have installed against the
          affected versions of vulnerable plugins. </p>
        <button className="btn" onClick={async () => {
          checkVulns()
        }
        }>Check Plugin Vulnerabilities</button>

        <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Switch checked={testMode} onChange={(checked) => setTestMode(checked)} /> {'  '} Test Mode </span>
        {testMode && <p>Test mode only checks the first 15 websites to avoid going over our budget of API requests from Patchstack.</p>}
        {Object.keys(vulnerabilities).length > 0 &&
          <CSVLink data={vulnerabilities} className="btn">Download data</CSVLink>}

        <p>{status}</p>
        {Object.keys(earliestVersions).length > 0 &&
          <p>You have {Object.keys(earliestVersions).length} unique plugins.</p>
        }
        {errors.map((e) =>
          <p style={{ color: "red" }}>{e}</p>
        )}

      </header>
      <div className='Row'><button className={display == 'updates_only' ? "active" : "inactive"} onClick={() => setDisplay("updates_only")}>Show only updates required</button>
        <button className={display == 'all' ? "active" : "inactive"} onClick={() => setDisplay("all")}>Show all vulnerabilities</button>
      </div>
      {status != "Done" && status != "" && errors.length == 0 &&
        <ReactSpinner />}
      {display == "all" &&
        vulnerabilities.map((vulnPlugin) => (<Vulnerability vulnPlugin={vulnPlugin} />)
        )
      }
      {display == "updates_only" &&
        vulnerabilities.map((vulnPlugin) => (vulnPlugin.needsUpdate && <Vulnerability vulnPlugin={vulnPlugin} />)
        )
      }
      {
        plugins.forEach((plugin) => {
          <p>{plugin}</p>
        })
      }
    </div >
  );
}

export default App;
